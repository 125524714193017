import React, { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { MdPersonPin } from "react-icons/md";
import styled from "styled-components";
import ad from "./advert.jpg";

const EnterData: React.FC = () => {
  const [image, setImage] = useState<string>("");
  const [name, setName] = useState<string>("");

  const displayPix = (e: any) => {
    const file = e.target.files[0];
    const save = URL.createObjectURL(file);
    setImage(save);
    console.log(save);
  };

  const checkName = name.replaceAll(" ", "").length;
  return (
    <div>
      <Container>
        <WrapAll>
          <Wrapper>
            <AutoWrap>
              <ImageInfo>
                <Image src={image} />
              </ImageInfo>
              <AutoName>
                <strong> {name}</strong>
              </AutoName>
            </AutoWrap>
          </Wrapper>
          <AllInputWrap>
            <WrapInput>
              <LeftInputs>
                <Input
                  placeholder="Name"
                  value={name}
                  onChange={(e: any) => {
                    setName(e.target.value);
                  }}
                />
                <ImageLabel htmlFor="pix">Upload a photo</ImageLabel>
                <ImageInput
                  id="pix"
                  type="file"
                  accept="image/jpg, image/png, image/jpeg"
                  onChange={displayPix}
                />
                {checkName >= 4 && image !== "" ? (
                  <Nextbutton to="/design">See your invite card</Nextbutton>
                ) : null}
              </LeftInputs>
            </WrapInput>
          </AllInputWrap>
          <Pic>
            <Pics src={ad} />
          </Pic>
        </WrapAll>
      </Container>{" "}
    </div>
  );
};

export default EnterData;
const Pics = styled.img`
  object-fit: contain;
  height: 100vh;
`;
const Pic = styled.div``;

const Input = styled.input`
  margin-left: 50px;
  height: 40px;
  width: 200px;
  border: none;
  border-bottom: 1px solid #dde0ece2;
  color: #dde0ece2;
  padding-left: 10px;
  outline: none;
  font-size: 18px;
  ::placeholder {
    color: gray;
  }
`;

const ImageInput = styled.input`
  display: none;
`;

const ImageLabel = styled.label`
  background-color: #dde0ece2;
  color: gray;
  padding: 15px 20px;
  border-radius: 5px;
  margin: 15px 0;
  transition: all 360ms;
  width: 115px;
  margin-left: 50px;
  margin-top: 70px;
  :hover {
    transform: scale(0.98);
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: none;
  background-color: silver;
  object-fit: cover;
`;

const ImageInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 100%;
  background-color: #dde0ece2;
`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

const WrapAll = styled.div`
  width: 100vw;
  height: 100%;
  display: flex;
`;
const AllInputWrap = styled.div`
  display: flex;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
`;
const AutoName = styled.div`
  margin-left: 50px;
  margin-right: 20px;
  font-family: cursive;
  margin-top: 10px;
  color: gray;
`;
const AutoWrap = styled.div`
  width: 90%;
  height: 70%;
`;
const WrapInput = styled.div`
  width: 90%;
  height: 90%;
`;
const LeftInputs = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
`;
const Nextbutton = styled(NavLink)`
  text-decoration: none;
  background-color: #dde0ece2;
  border: none;
  color: grey;
  padding: 15px 20px;
  border-radius: 5px;
  margin: 15px 0;
  transition: all 360ms;
  width: 140px;
  margin-left: 50px;
  margin-top: 60px;
  :hover {
    transform: scale(0.98);
    cursor: pointer;
  }
`;
