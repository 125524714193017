import React from "react";
import { useRoutes } from "react-router-dom";
import Comp2 from "./Comp2";
import EnterData from "./EnterData";
import NewEnterData from "./NewEnterData";

const Allroutes: React.FC = (): any => {
  let element = useRoutes([
    {
      path: "/",
      element: <NewEnterData />,
    },
    {
      path: "/design",
      element: <Comp2 />,
    },
    {
      path: "/inputs",
      element: <EnterData />,
    },
  ]);
  return element;
};

export default Allroutes;
