import React, { useState, useRef } from "react";
import styled from "styled-components";
import pix from "./dummy.jpg";
import html2canvas from "html2canvas";

const Comp2: React.FC = () => {
  const printRef: any = React.useRef();

  const handleDownloadImage = async () => {
    const element = printRef.current;
    const canvas = await html2canvas(element);

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = "image.jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  return (
    <div>
      <Container ref={printRef}></Container>{" "}
      <button onClick={handleDownloadImage}>Download</button>
    </div>
  );
};

export default Comp2;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
