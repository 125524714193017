import React from "react";
import Allroutes from "./Components/Allroutes";
import EnterData from "./Components/EnterData";

const App: React.FC = (): any => {
  return (
    <div>
      <Allroutes />
    </div>
  );
};

export default App;
