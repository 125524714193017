import React, { useState, useRef } from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import card from "./Group 1.png";
import back from "./hint.jpg";

const NewEnterData: React.FC = () => {
  return (
    <div>
      <Container>
        <Wrapper>
          <InviteCard>
            <Invite src={card} />
          </InviteCard>
          <InviteText>
            <Head>Congratulations!</Head>
            <About>
              You have been invited to codelab's tech forum.
              <br />
              Powered by set 06.
            </About>
            <Go to="/inputs">Accept Invite</Go>
          </InviteText>
        </Wrapper>
      </Container>
    </div>
  );
};

export default NewEnterData;

const Go = styled(NavLink)`
  text-decoration: none;
  color: white;
  font-family: cursive;
  transition: all 350ms;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;
const Container = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${back});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Wrapper = styled.div`
  width: 60%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
const InviteCard = styled.div``;
const Head = styled.h2`
  font-family: cursive;
`;
const About = styled.p`
  font-family: cursive;
`;
const Invite = styled.img`
  object-fit: contain;
  height: 500px;
`;
const InviteText = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 15px;
  color: white;
`;
